var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"loginviaotp"}},[_c('Header'),_c('div',{staticClass:"breadcrumb-wrap bg-f br-1"},[_c('div',{staticClass:"overlay bg-black op-9"}),_c('img',{staticClass:"br-shape-1",attrs:{"src":_vm.shape1,"alt":"Image"}}),_c('img',{staticClass:"br-shape-2",attrs:{"src":_vm.shape2,"alt":"Image"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"breadcrumb-title"},[_c('h2',[_vm._v("Login Via OTP")]),_c('ul',{staticClass:"breadcrumb-menu list-style"},[_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":"/"}},[_vm._v("Home  →")]),_c('li',[_vm._v("Login Via OTP")])],1)])])])])]),_c('section',{staticClass:"login-wrap pt-100 pb-100"},[_c('img',{staticClass:"login-shape-1",attrs:{"src":_vm.shape1,"alt":"Image"}}),_c('img',{staticClass:"login-shape-2",attrs:{"src":_vm.shape2,"alt":"Image"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2"},[_c('div',{staticClass:"login-form"},[_vm._m(0),_c('div',{staticClass:"login-body"},[_c('form',{staticClass:"form-wrap",attrs:{"action":"#"}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-lg-6 col-md-6 col-12 text-md-end mb-20"},[_c('router-link',{attrs:{"to":"/forgetpassword"}},[_vm._v("Forget Password")])],1),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('router-link',{staticClass:"btn style1 w-100 d-block",attrs:{"to":"/otp"}},[_vm._v("Send OTP")])],1)]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-md-12 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Don’t Have an Account? "),_c('router-link',{attrs:{"to":"/register"}},[_vm._v("Register")])],1)])])])])])])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-header bg-green"},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v("Login Via OTP")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{attrs:{"id":"email","name":"email","type":"email","placeholder":"Email Address*","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Phone Number")]),_c('input',{attrs:{"id":"email","name":"email","type":"email","placeholder":"Phone Number*","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6 col-12"},[_c('div',{staticClass:"form_group mb-20"},[_c('input',{attrs:{"type":"checkbox","id":"test_1"}}),_c('label',{attrs:{"for":"test_1"}},[_vm._v("Remember Me")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"or-text"},[_c('p',[_vm._v("Or Login With")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('ul',{staticClass:"social-profile style3 text-center mt-0 list-style"},[_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://instagram.com/"}},[_c('i',{staticClass:"lab la-instagram"})])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://twitter.com/"}},[_c('i',{staticClass:"lab la-twitter"})])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://facebook.com/"}},[_c('i',{staticClass:"lab la-facebook-f"})])])])])])
}]

export { render, staticRenderFns }